.MainContainer {
    display: grid;
    box-sizing: border-box;
    border-radius: 4px;
    grid-template-columns: 100%;
    grid-template-rows: 24rem auto;
}

h2, h3 {
    font-family: 'Syne Mono', monospace;
}

.ImageAndDetailContainer {
    display: grid;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    grid-template-columns: 1fr;
    grid-template-rows: 10fr 8fr;
    margin: 1px 2px;
}

.ContainerImage {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.Detail {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    vertical-align: center;
    margin: 5px 0;
}

.Text {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    margin-top: 1rem;
}

.Image {
    width: 100%;
    height: 100%;
    vertical-align: top;
}

.Name {
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding-top: 5px;
    font-size: 20px
}

.Profession {
    text-align: center;
    margin: 0;
    font-style: italic;
}

.Origin {
    text-align: center;
    margin: 0;
}

.Hobby {
    text-align: center;
    margin: 0;
}

.Story {
    width: 40%;
    margin: auto;
}

.SocialContent{
    margin: 0.5rem 0.5rem;
}

.SocialContents {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.StoryText {
    text-align: justify;
}

@media (min-width: 48rem) and (max-width: 64rem) {
    .MainContainer {
        display: grid;
        box-sizing: border-box;
        box-shadow: 2px 2px 2px 2px rgba(0.1, 0.1, 0.1, 0.1);
        border-radius: 4px;
        margin: auto 2.5rem 1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 25rem auto;
        background: #fafafe;
    }

    .ImageAndDetailContainer {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        grid-template-columns: 40% 60%;
        grid-template-rows: 1fr;
        margin: 1px 0 0 1px;
        box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.01);
    }

    .ContainerImage {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        width: 100%;
        max-width: 40rem;
        overflow: hidden;
    }

    .Detail {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Text {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        margin-top: 1rem;
        padding-bottom: 1rem;
    }

    .Name {
        font-size: 3rem
    }
}

@media (min-width: 64.1rem) {
    .MainContainer {
        display: grid;
        min-width: 60rem;
        box-sizing: border-box;
        border-radius: 4px;
        margin: auto 10rem;
        grid-template-columns: 100%;
        grid-template-rows: 25rem auto;
    }

    .ImageAndDetailContainer {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: 1fr;
        margin: 1px 0 0 1px;
        box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.01);
    }

    .ContainerImage {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        width: 100%;
        max-width: 40rem;
        overflow: hidden;
    }

    .Detail {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        font-size: 20px;
        margin-top: 2rem;
        align-items: center;
    }

    .Text {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        margin-top: 1rem;
    }

    .Name {
        font-size: 3rem
    }
}