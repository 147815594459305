.cards{
    display: grid;
    gap: 1rem;
    max-width: 1340px;
    margin: 0 auto;
    padding: 1rem;
}

/*@media (min-width: 40rem){*/
/*    .cards {*/
/*    grid-template-columns: repeat(2, 1fr);*/
/*    }*/
/*}*/

.card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr auto;
    /*background: #d9dcdc;*/
    border-radius: 10px;
    border: 1px solid #dcdacb;
    transition: linear 100ms;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    opacity: .8;
    color: #101010;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    /*background-color: #4688c4;*/
}

.text {
    color: black;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /*text-align: center;*/
}

.cards:hover {
    opacity: 1;
    /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
    /*background: #cba8c1;*/
}


@media (min-width: 40rem) {
    .card {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr auto;
    }
}

.cardImage {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background: no-repeat center center/cover;
    position: relative;
}

.cardImage:hover .overlay {
    opacity: 1;

}

.cardImage:hover img {
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
}
@-webkit-keyframes flash {
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flash {
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 40rem) {
    .cardImage {
        grid-column: 1 / 2;
        grid-row: 2 / 2;
    }
}

.cardContent {
    grid-column: 1 / 2;
    grid-row: 2 / 2;
    padding: 1.5rem;
}

@media (min-width: 40rem) {
    .cardContent {
        grid-column: 2 / 2;
        grid-row: 2 / 2;
    }
}

.cardTitle {
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 0.75rem;

}

.cardDescription {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
}

.cardButton {

}