.FooterItems {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #000000;
    color: white;
    border-bottom: solid 2px black;
    align-items: center;
}

.FontAwesome {
    font-size: 1.875rem;
    color: black;
    text-align: center;
}

.FontAwesome:hover {
    color: #b3d7ff;
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.SocialContent{
    margin: 0.5rem 0.5rem;
}
.SocialContents {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

