.NavigationItem {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    height: 90%;
}

.NavigationItem a {
    /*color: #2c618f;*/
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
    /*color: #40A4C8;*/
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 10px 0;
        display: block;
        align-items: stretch;
    }

    .NavigationItem a {
        color: black;
        padding: 10px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
        background-color: #fff;
        border-bottom: 4px solid transparent;
        color: #4688c4;
    }
}
