.Logo {
    height: 80px;
    cursor: pointer;
}

.Right {
    justify-content: flex-end;
}

.NavFont {
    font-size: 1.2em;
}