a:hover,
a:active {
    background: lightskyblue;
    color: black;
}

.name {
    margin: 0.5rem 0;
}

.tag {
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-weight: bold;
    margin: 0.5rem 0;
}

.cards {
    text-align: center;
    margin: 1rem;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    border: 1px solid #dcdacb;
    border-radius: 5px;
    transition: linear 100ms;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    opacity: .8;
    cursor: pointer;
}

.cards:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
