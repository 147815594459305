.Stories {
    text-align: center;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    color: #0984e3;
    font-weight: bold;
    font-size: larger;
    padding: 1rem;
}

.Divider{
    width: 2.5rem;
    margin: auto;
    background: #0984e3;
}

.storiesDivider{
    padding: 2.5rem;
}

.StoriesCard {
    text-align: center;
    padding: 2.5rem;
    margin: 0.5rem 0;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    border: 1px solid #dcdacb;
    border-radius: 5px;
    transition: linear 100ms;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    opacity: .8;
}

@media (min-width: 40rem) {
    .cardsList{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}