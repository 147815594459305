* {
    box-sizing: border-box;
}

body {
    font-family: 'Rajdhani', sans-serif, Serif;
    margin: 0;
}

.Hero {
    width: 100vw;
    height: 33vh;
    object-fit: cover
}

.HomeContentStoriesContainer {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    margin: 0;
}

.HomeContent {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding: 1rem 0.5rem;
    text-align: justify;
}

.Stories {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    padding-right: 2rem;
    text-align: justify;
    box-shadow: 3px 3px 5px 3px rgba(0.1, 0.1, 0.1, 0.1);
}

.Card {
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (min-width: 48rem) and (max-width: 64rem) {
    .HomeContentStoriesContainer {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        padding: 0 0.5rem;
        margin: 0;
    }

    .HomeContent {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        padding: 1rem 0.5rem;
        text-align: justify;
    }

    .Stories {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        display: flex;
        justify-content: center;
        padding: 0;
    }

    .Card {
        margin: 0;
        align-content: center;
        flex-direction: row;
    }
}

@media (min-width: 64.1rem) {
    .HomeContentStoriesContainer {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        margin: 0 8rem;
    }

    .HomeContent {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        padding: 1rem 0.5rem;
        text-align: justify;
    }

    .Stories {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        display: flex;
        width: inherit;
        justify-content: center;
        padding: 0;
    }

    .Card {
        margin: 0;
        align-content: center;
        flex-direction: row;
    }
}
